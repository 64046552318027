<form (ngSubmit)="checkPassword(confirmPasswordModale)" [formGroup]="persoForm">
    <div class="alert-info p-1 mb-2">
        <b>Pour votre sécurité !</b> 🔒 <br>
        Aidez-nous à protéger votre compte en renseignant vos informations. Cette étape simple nous permet de vérifier votre identité et de lutter contre la fraude. Merci pour votre confiance ! 😊
    </div>

    <div class="form-row">
        <div class="form-group col-md-6">
            <label>Genre<small>*</small></label>
            <div>
                <div class="custom-control custom-radio custom-control-inline male">
                    <input class="custom-control-input"
                           formControlName="title"
                           id="title-male"
                           name="title"
                           type="radio"
                           value="M"
                            >
                    <label class="custom-control-label" for="title-male">
                        <i class="far fa-male mr-1"></i>Homme
                    </label>
                </div>
                <div class="custom-control custom-radio custom-control-inline female">
                    <input class="custom-control-input"
                           formControlName="title"
                           id="title-female"
                           name="title"
                           type="radio"
                           value="MME">
                    <label class="custom-control-label" for="title-female">
                        <i class="far fa-female mr-1"></i>Femme
                    </label>
                </div>
            </div>
            <val-errors controlName="title"></val-errors>
        </div>
    </div>

    <div class="form-row">
        <div *ngIf="persoForm.value.title == 'MME'" class="alert-danger p-1 mb-2">Attention, si vous vous êtes mariée et que vous avez changé de nom, merci de nous fournir votre nom de naissance présent sur votre carte d'identité ou passeport</div>
        <div class="form-group">
            <label for="lastname">Nom<small>*</small></label>
            <input class="form-control"
                   data-cy="lastname"
                   formControlName="lastname"
                   id="lastname"
                   name="lastname"
                   type="text">
            <val-errors controlName="lastname"></val-errors>
        </div>
    </div>

    <div class="form-row">
        <div class="form-group col-md-6">
            <label for="firstname">Prénom<small>*</small></label>
            <input class="form-control"
                   data-cy="firstname"
                   formControlName="firstname"
                   id="firstname"
                   name="firstname"
                   type="text">
            <val-errors controlName="firstname"></val-errors>
        </div>

    </div>
    <div class="form-row">
        <div class="form-group col-md-6 col-sm-6">
            <label for="firstname">Date de naissance<small>*</small></label>
            <mcv-datepicker data-cy="birthday"
                            formControlName="birthday"></mcv-datepicker>
            <val-errors controlName="birthday"></val-errors>
        </div>
    </div>

    <div class="form-row">
        <div class="form-group col-md-6">
            <label for="occupation_category_id">Secteur d'activité<small>*</small></label>
            <select class="form-control"
                    formControlName="occupationCategory"
                    id="occupation_category_id"
                    name="occupation_category_id">
                <option value="1">Écolier, étudiant ou apprenti</option>
                <option value="2">Bénéficiaires d'aide à l'emploi</option>
                <option value="3">Sans activité professionnelle</option>
                <option value="4">Cadre du secteur privé</option>
                <option value="5">Employé du secteur privé</option>
                <option value="6">Chef d'entreprise</option>
                <option value="7">Cadre du service public</option>
                <option value="8">Employés du service public</option>
                <option value="9">Agriculteur</option>
                <option value="10">Auto-entrepreneur</option>
                <option value="11">Artisan</option>
                <option value="12">Commerçants et professions apparentées</option>
                <option value="13">Freelances</option>
                <option value="14">Professions médicales libérales</option>
                <option value="15">Retraité(e)</option>
            </select>
            <val-errors controlName="trz_occupation_category_id"></val-errors>
        </div>
    </div>

    <div class="form-row">
        <div class="form-group col-md-6">
            <label for="occupation">Profession<small>*</small></label>
            <input class="form-control"
                   formControlName="occupation"
                   id="occupation"
                   name="occupation" />
            <val-errors controlName="lastname"></val-errors>
        </div>
    </div>

    <div class="form-row">
        <div class="form-group col-md-6">
            <label for="monthly_income_range_id">Revenus mensuels<small>*</small></label>
            <select class="form-control"
                    formControlName="monthlyIncomeRange"
                    id="monthly_income_range_id"
                    name="monthly_income_range_id">
                <option value="1">- de 999 Euros par mois</option>
                <option value="2">Entre 1000 et 1800 Euros par mois</option>
                <option value="3">Entre 1801 et 3500 Euros par mois</option>
                <option value="4">Entre 3501 et 5000 Euros par mois</option>
                <option value="5">Entre 5001 et 6500 Euros par mois</option>
                <option value="6">Entre 6501 et 10000 Euros par mois</option>
                <option value="7">Plus de 10000 Euros par mois</option>
            </select>
            <val-errors controlName="trz_monthly_income_range_id"></val-errors>
        </div>
    </div>

    <div class="form-row mt-3">
        <div class="col-md-6 offset-md-3">
            <button [disabled]="!persoForm.valid || saving"
                    [promiseBtn]="saving"
                    class="btn btn-primary rounded-pill btn-block"
                    type="submit">
                Valider mes informations
            </button>
        </div>
    </div>
    <div class="form-row">
        <div class="col">
            <small>*: mention obligatoire</small>
        </div>
    </div>
</form>

<ng-template #confirmPasswordModale>
    <div class="modal-header">
        <h5 class="modal-title">Confirmer votre mot de passe</h5>
        <button (click)="hide()" aria-label="Close" class="close" data-dismiss="modal" type="button">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form (ngSubmit)="confirmPassword()" [formGroup]="confirmPasswordForm">
            <p>
                Merci de confirmer votre mot de passe pour modifier vos informations personnelles.
            </p>
            <div class="form-group">
                <label class="control-label" for="password">Mot de passe :</label>
                <mcv-password-input autocomplete="password"
                                    data-cy="password"
                                    formControlName="password"
                                    id="password"
                                    name="password"></mcv-password-input>
            </div>

            <div class="form-group">
                <button class="btn btn-primary btn-block" type="submit">Valider le mot de passe</button>
            </div>

            <div class="form-group">
                <button (click)="hide()" class="btn btn-outline-secondary btn-block" type=button>
                    Annuler
                </button>
            </div>
        </form>
    </div>
</ng-template>
