import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BASEURL } from '@mcv/config';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { McvnotifierService, mobilePhoneValidator } from '@mcv/ui';
import { MobileCheckService } from '@cseemploye/service/mobile-check.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AuthService } from '@cseemploye/app/auth/services/auth.service';
import { ConfigurationsService } from '@cseemploye/app/auth/services/configurations.service';

@Component({
    selector: 'mcv-index',
    templateUrl: './index.component.html',
    styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {
    public mobileForm: FormGroup;
    public codeForm: FormGroup;

    public checking = false;
    public modalId: number;

    @ViewChild('codeCheckModal') codeCheckModalRef: TemplateRef<any>;

    constructor(
        @Inject(BASEURL) public baseUrl: string,
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private mobileCheckService: MobileCheckService,
        public modalService: BsModalService,
        private authService: AuthService,
        private configService: ConfigurationsService,
        private notifierService: McvnotifierService
    ) {
        this.mobileForm = this.fb.group({ mobile: [null, [Validators.required, mobilePhoneValidator()]] });
        this.codeForm = this.fb.group({ code: [null, [Validators.required, Validators.pattern(/[0-9]{6}/)]] });
    }

    ngOnInit(): void {
        const authCode = this.route.snapshot.queryParams['code'];

        if (this.authService.isLoggedIn() === false || this.authService.isLoggedIn() === null) {

            let redirectTo =  '/mobile-check';

            if (authCode) {
                redirectTo += `?code=${authCode}`;
            }

            this.router.navigate(['/auth/login'], { queryParams: { redirectTo } });
            return;
        }

        if (this.authService.user.mobile_check) {
            this.router.navigate(['/client/transaction/index']);
            return;
        }

        if (authCode) {
            this.mobileCheckService
                .submitCode(authCode)
                .catch(_ => this.router.navigate(['/auth/login']));
        } else {
            this.router.navigate(['/auth/login']);
        }
    }

    closeModal(): void {
        this.modalService.hide(this.modalId);
    }

    onCodeFormSubmit() {
        this.mobileCheckService.verify(this.mobileForm.get('mobile').value)
            .then(_ => {
                this.modalId = this.modalService.show(this.codeCheckModalRef, { ignoreBackdropClick: true }).id;
                this.router.navigate(['.'], {
                    queryParams: { checkCode: 1 },
                    skipLocationChange: true,
                    relativeTo: this.route
                });
            });
    }

    confirmCode() {
        this.checking = true;
        this.mobileCheckService.confirmCode(
            this.mobileForm.get('mobile').value,
            this.codeForm.get('code').value
        )
            .then(_ => {
                this.notifierService.success('Votre numéro à été vérifié avec succès');

                this.configService.loadAppConfig().toPromise().then(_ => {
                    this.modalService.hide(this.modalId);
                    this.router.navigate(['/client/transaction/index']);
                    this.checking = false;
                });
            }).catch(_ => {
                this.checking = false;
            })
        ;
    }
}
