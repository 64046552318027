<div class="text-center mt-4 mb-4" style="">
    <img src="{{baseUrl}}/img/logo.png" style="max-height: 60px; max-width: 90%;">
</div>

<div class="card border-light m-md-5 p-md-4">
    <div class="card-body">
        <div class="card-title row">
            <div class="col"><h2>Vérification de votre mobile</h2></div>
        </div>

        <div class="row">
            <p class="col">Veuillez entrer un nouveau mobile que nous vérifierons en vous envoyant un code par SMS :</p>
        </div>

        <form [formGroup]="mobileForm" (ngSubmit)="onCodeFormSubmit()">
            <div class="row">
                    <div class="col">
                        <mcv-phone-input class="mx-auto" formControlName="mobile" id="mobile"></mcv-phone-input>
                    </div>
            </div>

            <div class="row justify-content-center">
                <div class="col col-md-4 col-xl-2">
                    <button
                        [disabled]="!mobileForm.valid"
                        class="btn btn-primary btn-block mx-auto mt-4"
                        type="submit">
                        Valider
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>

<ng-template #codeCheckModal>
    <div class="modal-header">
        <h4 class="modal-title align-self-center" id="dataConfirmLabel">Confirmation SMS</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
            <i class="fas fa-times" aria-hidden="true"></i>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="codeForm" (ngSubmit)="confirmCode()">


            <div class="form-group">
                <p>Un SMS a été envoyé au numéro fourni.</p>
                <p>Veuillez entrer le code reçu.</p>
            </div>

            <div class="form-group">
                <div class="form-row">
                    <label for="code">Code de vérification</label>
                </div>
                <div class="form-row">
                    <input type="text" id="code" class="mx-auto" formControlName="code">
                </div>
            </div>

            <div class="form-group">
                <button
                    [disabled]="!codeForm.valid"
                    [promiseBtn]="checking"
                    class="btn btn-primary btn-block mx-auto"
                    type="submit">
                    Valider
                </button>
            </div>
        </form>
    </div>
</ng-template>
